
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
}

.Rectangle , .RectangleVertical {
    position: fixed;
    top: 10%;
    left: 20%;
    width: 60vw;
    border: solid 0.5px #fff;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.5), 0 0 16px 0 rgba(0, 0, 0, 0.5);
    background-color: #1f2641;
    display:block;
}
.Connecting, .ConnectingVertical  {
    position: fixed;
    top: 20%;
    left: 20%;
    width: 60vw;
    border: solid 0.5px #000000;
    border-radius: 10px;
    background-color: #FF7100;
    display:block;
}
.Connecting {
    top: 40%;
    height: 20%;

}
.ConnectingVertical  {
    top:45vh;
}
.RectangleVertical{
    top: 35%;
    width:70%;
    left: 16%;
}

.title , .text , .ConnectingContent, .ConnectingContentVertical, .verticalTitle, .verticalText{
    margin: 2% 20%;
    font-family: Mukta Mahee;
    font-size: 2.85rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width:60%;
}
.verticalTitle{
    font-size: clamp(1rem, 10vw, 2rem);
}
.verticalText{
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: clamp(1.25rem, 3vw, 1.75rem); }
.text {
    margin: 5% 20%;
    font-size: 2rem;
    margin-bottom: 5%;

}
.ConnectingContent ,.ConnectingContentVertical{
    display: inline-flex;
    margin-top: -8vh;
}
.ConnectingTitle, .ConnectingTitleVertical{
    margin-left: 5%;
}
.ConnectingContentVertical{
    margin-left:  20%;
    margin-top: auto;
    font-size: clamp(1.5rem, 3vw, 1.75rem);
}
.LoadingIcon{
    min-width: 2rem;
    margin: auto;
    width: 1em;
}



